<template>
  <div id="index-container">
    <svg class="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="12" r="8" fill="#FF0000" fill-opacity="0.4"/>
      <path d="M6 11.4142V18C6 18.5523 6.44772 19 7 19H9C9.55228 19 10 18.5523 10 18V15C10 14.4477 10.4477 14 11 14H13C13.5523 14 14 14.4477 14 15V18C14 18.5523 14.4477 19 15 19H17C17.5523 19 18 18.5523 18 18V11.4142C18 11.149 17.8946 10.8946 17.7071 10.7071L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L6.29289 10.7071C6.10536 10.8946 6 11.149 6 11.4142Z" stroke="black"/>
    </svg>

  </div>
</template>

<script>
export default ({
})
</script>
<style >
.icon:hover path{
  stroke-width: 2px;
}
.icon:hover circle{
  fill: blue;
  transform: scale(1.2);
  transform-origin: center center;
}

.icon{
  width: 96px;
  height: 96px;
  overflow: visible;
}

#index-container {
  width: 100%;
  height: 60vh;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>